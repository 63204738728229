import React from 'react';
import { Route, Redirect } from 'react-router';
import { getToken, getLastLoginTime } from '@UTILS/authority';
// import { getToken } from '@UTILS/authority'

const EFFECTIVE_TIME = 86400000;

export default class AuthorityRoute extends React.PureComponent {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isAuthority: true
  //   };
  // }

  // static getDerivedStateFromProps(nextProps) {
  //   if (nextProps.authority) {

  //   }
  //   // let isAuthority = null;
  //   // if (Array.isArray(nextProps.authority)) {
  //   //   if (nextProps.authority.some(_ => _ === getAuthority())) {
  //   //     isAuthority = true;
  //   //   } else {
  //   //     isAuthority = false;
  //   //   }
  //   // } else if (nextProps.authority === getAuthority()) {
  //   //   isAuthority = true;
  //   // } else {
  //   //   isAuthority = false;
  //   // }
  //   // return {
  //   //   isAuthority
  //   // };
  // }

  render() {
    const { path, render, redirectPath } = this.props;
    // const { isAuthority } = this.state;
    const token = getToken();
    const lastTime = getLastLoginTime();
    const isActive = token && new Date().getTime() - lastTime < EFFECTIVE_TIME;
    return (
      <Route
        path={path}
        render={isActive ? render : () => <Redirect to={redirectPath} />}
      />
    );
  }
}
