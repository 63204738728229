export const DELIVERY_METHODS = {
  BellAlert: {
    Name: 'BellAlert',
    DisplayText: '通知'
  },
  PusherNotice: {
    Name: 'PusherNotice',
    DisplayText: 'Notice'
  },
  PusherNoticeAndBellAlert: {
    Name: 'PusherNoticeAndBellAlert',
    DisplayText: '通知+Notice'
  },
  ChatMessage: {
    Name: 'ChatMessage',
    DisplayText: '私信'
  },
};

export const ENTITY_TYPE_ENUM = {
  FeedItem: {
    Name: 'FeedItem',
    DisplayText: '资讯'
  },
  Opportunity: {
    Name: 'Opportunity',
    DisplayText: '机会'
  },
  Course: {
    Name: 'Course',
    DisplayText: '课程'
  },
  Guide: {
    Name: 'Guide',
    DisplayText: '指南'
  },
  MedWord: {
    Name: 'MedWord',
    DisplayText: '词汇'
  },
  // Article: {
  //   Name: 'Article',
  //   DisplayText: '文章'
  // }
  // ExternalUrl: {
  //   Name: 'ExternalUrl',
  //   DisplayText: '链接',
  // }
};

export const RECIPIENT_SCOPE_ENUM = {
  All: {
    Name: 'All',
    DisplayText: '全部用户'
  },
  Partial: {
    Name: 'Partial',
    DisplayText: '部分用户'
  },
  ResidencyTrainAll: {
    Name: 'ResidencyTrainAll',
    DisplayText: '全部规培用户',
  },
  ResidencyTrainPartial: {
    Name: 'ResidencyTrainPartial',
    DisplayText: '部分规培用户',
  }
};

export const DISPLAY_FREQUENCY_ENUM = {
  Once: {
    Name: 'Once',
    DisplayText: '只显示1次'
  },
  Every: {
    Name: 'Every',
    DisplayText: '每次打开App显示'
  }
};

export const VERSION_SCOPE_ENUM = {
  International: {
    Name: 'International',
    DisplayText: '全部版本'
  },
  Chinese: {
    Name: 'Chinese',
    DisplayText: '中文用户'
  },
  English: {
    Name: 'English',
    DisplayText: '英文用户'
  }
};

export const RESIDENCY_TRAIN_SCOPE = [{
  label: '带教老师（UTD）',
  value: 'TeacherWithUtd',
}, {
  label: '带教老师（无UTD）',
  value: 'TeacherWithoutUtd',
}, {
  label: '规培学员（UTD）',
  value: 'StudentWithUtd',
}, {
  label: '规培学员（无UTD）',
  value: 'StudentWithoutUtd',
}]

export const TIMING_ENUM = {
  Instant: {
    Name: 'Instant',
    DisplayText: '即时推送'
  },
  Future: {
    Name: 'Future',
    DisplayText: '定时推送'
  }
};

export const PUBLISH_ENUM = {
  Instant: {
    Name: 'Instant',
    DisplayText: '即时发布'
  },
  Future: {
    Name: 'Future',
    DisplayText: '定时发布'
  }
};

export const TRIGGER_STATUS = {
  Draft: '草稿',
  Pending: '待推送',
  InProgress: '推送中',
  Completed: '已推送',
  Failed: '推送失败'
};

export const GUIDE_STATUS = {
  Active: '已发布',
  Draft: '草稿',
  Archived: '已删除'
};

export const TRIGGER_STATUS_ENUMS = {
  Draft: 'Draft',
  Pending: 'Pending',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Failed: 'Failed'
};

export const ORDER_BY = {
  DeliveryDate: {
    Name: 'DeliveryDate',
    DisplayText: '推送时间'
  },
  HitNumber: {
    Name: 'HitNumber',
    DisplayText: '点击量'
  }
};

export const EXPIRED_STATUS = {
  Expired: '已过期',
  UnExpired: '未过期'
}

export const LINK_TYPE = {
  feedItemDetail: '/activity/',
  opportunityDetail: '/opportunity/',
  friendDetail: '/userProfile/',
  guideDetail: '/guide/',
  courseDetail: '/course/',
}

export const LINK_TYPE_ENUM = {
  feedItemDetail: 'feedItemDetail',
  opportunityDetail: 'opportunityDetail',
  friendDetail: 'friendDetail',
  guideDetail: 'guideDetail',
  courseDetail: 'courseDetail',
  groupDetail: 'groupDetail',
  wmpRoute: 'wmpRoute',
  decisionFlowIndex: 'decisionFlowIndex',
  decisionFlow: 'decisionFlow',
}

export const USER_TYPE_ENUM = {
  Both: '全部用户',
  en: '英文用户',
  zh: '中文用户'
}

export const SPOT_CODE = {
  AcademyBanner: 'Academy001',
  HomeBanner: 'HomeBanner',
  HomeGrid: 'HomeGrid',
  Courseware: 'Courseware',
  CourseShortcuts: 'CourseShortcuts',
  HotTopics: 'HotTopics',
  OpportunityBanner: 'OpportunityBanner',
  OpportunityGrid: 'OpportunityGrid',
  AcademyTopic: 'AcademyTopic',
  GuideGrid: 'GuideGrid',
  HomePopup: 'HomePopup',
  HomeAcademy: 'HomeAcademy',
}

export const ROUTE_TYPE_MAP = {
  tagPageList: {
    Type: 'tagPageList',
    DisplayText: '标签主页',
    IdKey: 'TagId',
  },
}

export const COVER_SIZE = {
  HomeBanner: {
    zh: 375 / 164,
    en: 375 / 164,
  },
  Academy001: {
    zh: 345 / 85,
    en: 345 / 85
  },
  Courseware: {
    zh: 120 / 70,
    en: 120 / 70
  },
  OpportunityBanner: {
    zh: 320 / 198,
    en: 320 / 198
  },
  OpportunityGrid: {
    zh: 120 / 172,
    en: 120 / 166
  },
  AcademyTopic: {
    zh: 16 / 16,
    en: 16 / 16
  },
  GuideGrid: {
    zh: 100 / 100,
    en: 100 / 100
  },
  ClinicalDialogue: {
    zh: 225 / 120,
    en: 225 / 120,
  },
  HomePopup: {
    zh: 305 / 406,
    en: 305 / 406,
  },
  PainAcademy: {
    zh: 342 / 85,
    en: 342 / 85,
  },
  AcademyExperts: {
    zh: 178 / 162,
    en: 178 / 156,
  },
  NCovAcademy: {
    zh: 357 / 99,
    en: 357 / 99,
  },
  NCovTopic: {
    zh: 207 / 109,
    en: 207 / 109,
  },
  NCovAcademyBanner: {
    zh: 357 / 99,
    en: 357 / 99,
  },
  HomeBanner172: {
    zh: 109 / 145,
    en: 109 / 145,
  }
}

export const EXPORTFEED_OPERATOR_TYPE = {
  FeedItem: LINK_TYPE_ENUM.feedItemDetail,
  Opportunity: LINK_TYPE_ENUM.opportunityDetail,
  Guide: LINK_TYPE_ENUM.guideDetail,
  Course: LINK_TYPE_ENUM.courseDetail,
}

export const MESSAGE_TYPE_METHOD = {
  Text: {
    Name: 'Text',
    DisplayText: '文本'
  },
  Image: {
    Name: 'Image',
    DisplayText: '图片'
  },
  Link: {
    Name: 'Link',
    DisplayText: '链接'
  },
};

export const MESSAGE_TYPE_NOTE = {
  Chat: '私信',
  Guide: '指南',
  Article: '文章',
  Course: '视频',
  Opportunity: '机会',
  Post: '动态',
  Profile: '个人主页',
  Medicine: '今日医学',
  Academy: '学院',
  MedWord: '词汇',
  Meetup: '会议',
  Tag: '标签主页',
}

export const MESSAGE_TYPE = {
  Chat: 'Chat',
  Guide: 'Guide',
  Article: 'Article',
  Course: 'Course',
  Opportunity: 'Opportunity',
  Post: 'Post',
  Profile: 'Profile',
  Medicine: 'Medicine',
};

export const MED_WORD_STATUS = {
  DRAFT: 'Draft',
  ACTIVE: 'Active',
  PENDING: 'Pending',
  ARCHIVED: 'Archived',
  SYNC: 'Sync',
}

export const MED_WORD_STATUS_ENUMS = {
  Draft: '草稿',
  Active: '已发布',
  Archived: '已删除',
  Pending: '定时发布',
}

export const SEARCH_COUTE_CLICK_BOOKMARK = {
  ClickCount: '查看数',
  BookmarkCount: '收藏数'
}

export const OPERATION_STATUS_ENUMS = {
  Draft: '下架',
  Active: '上架',
  Archived: '删除',
  Sync: '同步',
  ALREADYSYNC: '已同步'
}

export const OPTION_ROUTE_TYPE_MAP = [
  'courseListWithTag'
]
