import { call, put, select } from 'redux-saga/effects';
import api from '@API';
import { AuditEnums, LIST_DEFAULT_TAKE } from '@COMMON/constant';
import handleError from '@UTILS/handleError'

const maintainerList = state => state.group.maintainerList;

export default {
  * getGroups({ payload }) {
    try {
      const list = yield call(api.getGroups, {
        take: LIST_DEFAULT_TAKE,
        ...payload,
      });
      const { Groups, Total } = list;
      yield put({
        type: 'setState',
        payload: {
          groupList: Groups,
          totalGroupList: Total,
          loadingGroupList: false,
        }
      });
    } catch (error) {
      handleError(error);
    }
  },
  // 机构成员
  * getAssociationMembers({ payload }) {
    try {
      const memberList = yield call(api.getAssociationMembers, {
        take: LIST_DEFAULT_TAKE,
        ...payload,
      });
      const { Total, Members } = memberList;
      yield put({
        type: 'setState',
        payload: {
          groupMemberList: Members,
          totalGroupMember: Total
        }
      });
    } catch (error) {
      handleError(error);
    }
  },
  // 维护员申请
  * getModeratorApplicationsList({ payload }) {
    try {
      const list = yield call(api.getModeratorApplicationsList, {
        take: LIST_DEFAULT_TAKE,
        ...payload,
      });
      const { Petitions, Total } = list;
      yield put({
        type: 'setState',
        payload: {
          maintainerList: Petitions,
          maintainerTotle: Total
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * approveModeratorApplication({ payload }) {
    try {
      const list = yield select(maintainerList);
      const { PetitionId, modsNum, EntityId } = payload;
      const newSUm = modsNum + 1;
      const newList = list.map(item => (item.p8Id === PetitionId
        ? { ...item, Status: AuditEnums.Approved }
        : item));
      yield put({
        type: 'setState',
        payload: {
          maintainerList: newList.map(item => (item.EntityId === EntityId
            ? { ...item, ModeratorsNum: newSUm }
            : item))
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getModeratorDenyReasons() {
    try {
      const denyReason = yield call(api.getModeratorDenyReasons);
      yield put({
        type: 'setState',
        payload: {
          denyReason
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * denyModeratorApplication({ payload }) {
    const list = yield select(maintainerList);
    const { PetitionId } = payload;
    yield put({
      type: 'setState',
      payload: {
        maintainerList: list.map(item => (item.p8Id === PetitionId
          ? { ...item, Status: AuditEnums.Denied }
          : item))
      }
    });
  }
};
