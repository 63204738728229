export const COURSE_STATUS = {
  Active: '已发布',
  Archived: '已删除',
  Draft: '草稿'
};

export const COURSE_ENUMS = {
  Active: 'Active', //发布
  Draft: 'Draft', //草稿
  Archived: 'Archived' //删除
};

export const CourseUserType = {
  Both: '全部用户',
  English: '英文用户',
  Chinese: '中文用户'
};

export const VIDEO_TYPE = [
  'video/mp4',
  'video/wmv',
  'video/avi',
  'video/rmvb',
  'video/mov',
  'video/flv',
  'video/rm'
];

export const TextTrackType = {
  srt: 'SRT',
  ttml: 'TTML',
  vtt: 'VTT'
};

export const CourseLinkName = {
  en: '英文名称',
  zh: '中文名称',
};
