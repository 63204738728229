export function getAuthority() {
  return localStorage.getItem('pier-admin-authoriry');
}

export function setAuthority(authoriry) {
  return localStorage.setItem('pier-admin-authoriry', authoriry);
}

export function setToken(token) {
  return localStorage.setItem('pier-admin-token', token);
}

export function setLastLoginTime(time) {
  return localStorage.setItem('pier-admin-last-login-time', time);
}

export function getToken() {
  return localStorage.getItem('pier-admin-token');
}

export function getLastLoginTime() {
  return localStorage.getItem('pier-admin-last-login-time');
}

export function getStsToken() {
  return JSON.parse(localStorage.getItem('pier-admin-sts-token'));
}

export function setStsToken(token) {
  return localStorage.setItem('pier-admin-sts-token', JSON.stringify(token));
}

export function getSecurityErrorInfo() {
  return JSON.parse(localStorage.getItem('pier-admin-last-login-info'));
}

export function setSecurityErrorInfo(record) {
  return localStorage.setItem('pier-admin-last-login-info', JSON.stringify(record));
}

export function removeSecurityErrorInfo() {
  return localStorage.removeItem('pier-admin-last-login-info');
}
