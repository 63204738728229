import { message } from 'antd';
import { call, put, select, all } from 'redux-saga/effects';
import api from '@API';
import { OPPORTUNITY_STATUS_ENUMS } from '@COMMON/opportunity';
import { LIST_DEFAULT_TAKE } from '@COMMON/constant';
import { mapList } from '@UTILS/utils';
import handleError from '@UTILS/handleError'

const ownOpportunityList = state => state.opportunity.ownOpportunityList;
const opportunityList = state => state.opportunity.opportunityList;

export default {
  * getOpportunityList({ payload }) {
    try {
      const { Total, Opportunities } = yield call(api.getOpportunityList, {
        ...payload,
        take: LIST_DEFAULT_TAKE,
      });
      yield put({
        type: 'setState',
        payload: {
          opportunityList: Opportunities,
          opportunityTotal: Total,
          opportunityLoading: false
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * closeOpportunity({ payload }) {
    const { p8Id } = payload;
    try {
      yield call(api.closeOpportunity, { OpportunityId: p8Id });
      const list = yield select(opportunityList);
      message.success('机会已关闭');
      yield put({
        type: 'setState',
        payload: {
          opportunityList: mapList(list, 'p8Id', p8Id, { Status: OPPORTUNITY_STATUS_ENUMS.Closed })
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * saveOpportunity({ payload }) {
    const { p8Id } = payload;
    try {
      yield call(api.saveOpportunity, payload);
      const list = yield select(opportunityList);
      message.success('机会已上架');
      yield put({
        type: 'setState',
        payload: {
          opportunityList: mapList(list, 'p8Id', p8Id, { Status: OPPORTUNITY_STATUS_ENUMS.Active })
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * recycleOpportunity({ payload }) {
    const { p8Id } = payload;
    try {
      const list = yield select(opportunityList);
      yield call(api.recycleOpportunity, { OpportunityId: p8Id });
      message.success('机会已下架');
      yield put({
        type: 'setState',
        payload: {
          opportunityList: mapList(list, 'p8Id', p8Id, { Status: OPPORTUNITY_STATUS_ENUMS.Draft })
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getOwnOpportunityList({ payload }) {
    try {
      const { Total, Opportunities } = yield call(api.getOwnOpportunityList, {
        take: LIST_DEFAULT_TAKE,
        sortCol: 'CreatedDate',
        ...payload,
      });
      yield put({
        type: 'setState',
        payload: {
          ownOpportunityList: Opportunities,
          ownOpportunityTotal: Total,
          ownOpportunityLoading: false
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * closeOwnOpportunity({ payload }) {
    const { p8Id } = payload;
    try {
      yield call(api.closeOpportunity, { OpportunityId: p8Id });
      const list = yield select(ownOpportunityList);
      message.success('机会已关闭');
      yield put({
        type: 'setState',
        payload: {
          ownOpportunityList: mapList(list, 'p8Id', p8Id, { Status: OPPORTUNITY_STATUS_ENUMS.Closed })
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * saveOwnOpportunity({ payload }) {
    const { p8Id } = payload;
    try {
      const { PublishDate } = yield call(api.saveOpportunity, payload);
      const list = yield select(ownOpportunityList);
      message.success('机会已上架');
      yield put({
        type: 'setState',
        payload: {
          ownOpportunityList: mapList(list, 'p8Id', p8Id, { Status: OPPORTUNITY_STATUS_ENUMS.Active, PublishDate })
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * recycleOwnOpportunity({ payload }) {
    const { p8Id } = payload;
    try {
      const list = yield select(ownOpportunityList);
      yield call(api.recycleOpportunity, { OpportunityId: p8Id });
      yield put({
        type: 'setState',
        payload: {
          ownOpportunityList: mapList(list, 'p8Id', p8Id, { Status: OPPORTUNITY_STATUS_ENUMS.Draft })
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * exportOpportunityToFeed({ payload }) {
    try {
      const { OpportunityId } = payload
      yield call(api.exportOpportunityToFeed, payload)
      const list = yield select(opportunityList);
      yield put({
        type: 'setState',
        payload: {
          opportunityList: mapList(list, 'p8Id', OpportunityId, { FeedItemId: true })
        }
      })
      message.success('机会同步成功')
    } catch (error) {
      handleError(error)
    }
  },

  * getApplicationList({ payload }) {
    try {
      yield put({
        type: 'setState',
        payload: {
          opportunityLoading: true,
        }
      })
      const [{ Applications }, { Count }] = yield all([
        call(api.getApplicationList, payload),
        call(api.countApplications),
      ])
      yield put({
        type: 'setState',
        payload: {
          applyOpportunityList: Applications,
          opportunityTotal: Count,
          opportunityLoading: false
        }
      });
    } catch (error) {
      handleError(error)
    }
  },
};
