/* eslint-disable  */
import i18next from 'i18next';
// import XHR from 'i18next-xhr-backend';
import { reactI18nextModule } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import Promise from 'bluebird';
// import Cookie from 'js-cookie';

import en from './en.json';
import zh from './zh.json';

const whitelist = ['en', 'zh'];
const fallbackLng = 'en';
// const DEFAULT_NAMESPACE = 'translation';
let i18nInstance;

export const getI18nInstance = () => i18nInstance;

export function strings(name, valueObj) {
  return i18nInstance.t(name, valueObj);
}

export const getLanguage = () => {
  const lang = i18next.language;
  return ['en', 'zh'].includes(lang) ? lang : 'en';
};

export function initI18next(language = 'zh') {
  return new Promise((resolve, reject) => {
    i18nInstance = i18next
      .use(reactI18nextModule)
      .use(LanguageDetector)
      // .use(XHR)
      .init(
        {
          load: 'languageOnly',
          lngs: whitelist,
          fallbackLng,
          lng: language,
          resources: {
            zh: {
              translation: zh
            },
            en: {
              translation: en
            }
          },
          interpolation: {
            escapeValue: false
          },
          react: {
            wait: false,
            bindI18n: 'languageChanged loaded',
            // bindStore: 'added removed',
            nsMode: 'default'
          }
        },
        err => {
          if (err) {
            return reject(err);
          }
          return resolve(i18next);
        }
      );
  });
}

initI18next();

export default i18nInstance;
