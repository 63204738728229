import React, { PureComponent } from 'react'
import Config from '@CONFIG';
import { loadAvatarImage } from '@UTILS/avatarSource';
import { UserStatusEnums, UserTypeEnums } from '@COMMON/constant';
import './Avatar.scss'

const getDefaultAvatar = (id, type) => ((id && type) ? loadAvatarImage(type, id) : require('@ASSETS/image/icon_sculpture@3x.png'))

class Avatar extends PureComponent {
  static defaultProps = {
    type: UserTypeEnums.Physician,
    pressDisabled: true
  }

  constructor(props) {
    super(props)
    this.state = {
      imageSource: require('@ASSETS/image/icon_sculpture@3x.png'),
      userId: '',
      status: ''
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { avatarVersion, type, userId, status, staticUrl = Config.staticAvatarUrl, extension = 'jpg', defaultAvatar } = nextProps
    if (!avatarVersion) {
      return {
        imageSource: defaultAvatar || getDefaultAvatar(userId, type),
        userId,
        status
      }
    }
    if (userId !== prevState.userId) {
      return {
        imageSource: `${staticUrl}${userId}.${extension}?t=${avatarVersion}`,
        userId,
        status
      }
    }
    return {
      userId,
      status
    }
  }

  noFind = () => {
    const { defaultAvatar } = this.props
    this.setState({
      imageSource: defaultAvatar || require('@ASSETS/image/icon_sculpture@3x.png')
    })
  }

  render() {
    const { imageSource, status } = this.state
    return (
      <div className='avatar-container'>
        <img className='avatar-image' src={imageSource} onError={this.noFind} alt='' />
        {status === UserStatusEnums.Verified && (
          <img className='verified-icon' src={require('@ASSETS/image/icon_vip_mini@3x.png')} alt='' />
        )}
      </div>
    )
  }
}

export default Avatar
