import React from 'react'

const DisabledRouter = () => {
  return (
    <div>
      <span>功能暂未开通</span>
    </div>
  )
}

export default DisabledRouter
