import React from 'react';
import { Switch, Route } from 'react-router';
import { CopyrightOutlined } from '@ant-design/icons';
import GlobalFooter from '@COMS/GlobalFooter';
import styles from './LoginLayout.less';
import AdminLogin from '../screens/AdminLogin';

export default class LoginLayout extends React.PureComponent {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <Switch>
            <Route path='/login' exact component={AdminLogin} />
          </Switch>
        </div>
        <GlobalFooter
          copyright={(
            <React.Fragment>
              Copyright
              {' '}
              <CopyrightOutlined />
              {' '}
2021 MedLinc
            </React.Fragment>
)}
          style={{ color: 'rgba(0,0,0,0.85)' }}
        />
      </div>
    );
  }
}
