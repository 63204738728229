import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { getAuthority } from '@UTILS/authority';
import styles from './index.less';

const { Sider } = Layout;
const { SubMenu } = Menu;

function searchOpenSubMenu(path) {
  const transPath = path.slice(1).split('/');
  const openKeys = [];
  let len = transPath.length;
  let startLen = 0;
  while (len - 1) {
    let str = '';
    for (let i = 0; i <= startLen; i += 1) {
      str += `/${transPath[i]}`;
    }
    openKeys.push(str);
    len -= 1;
    startLen += 1;
  }
  return openKeys;
}

export default class SiderMenu extends React.PureComponent {
  state = {
    openKeys: [],
    selectedKeys: []
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname !== prevState.selectedKeys[0]) {
      return {
        openKeys: searchOpenSubMenu(nextProps.location.pathname),
        selectedKeys: [nextProps.location.pathname]
      };
    }
    return null;
  }

  handleCreateMenu = menuData => {
    if (!menuData) {
      return [];
    }
    return (
      menuData
        .filter(item => item.name && !item.hidden)
        // .filter(item => this.handleFilterAuthorityMenu(item))
        .map(item => this.getSubMenuOrMenuItem(item))
        .filter(item => item)
    );
  };

  handleFilterAuthorityMenu = item => !item.authority || item.authority === getAuthority();

  getSubMenuOrMenuItem = item => {
    if (item.children && item.children.length > 0) {
      return this.handleCreateSubMenu(item);
    }
    return this.handleCreateMenuItem(item);
  };

  handleCreateMenuItem = ({ path, name, icon: IconComponent }) => (
    <Menu.Item key={path}>
      <Link to={path} replace={path === this.props.location.pathname}>
        {IconComponent ? <IconComponent /> : null}
        <span>{name}</span>
      </Link>
    </Menu.Item>
  );

  handleCreateSubMenu = ({ path, name, icon: IconComponent, children }) => (
    <SubMenu
      key={path}
      title={(
        <span>
          {IconComponent ? <IconComponent /> : null}
          <span>{name}</span>
        </span>
)}
    >
      {children.map(m => this.getSubMenuOrMenuItem(m))}
    </SubMenu>
  );

  handleOpenSubMenu = openKeys => {
    this.setState({
      openKeys: openKeys.length ? openKeys : []
    });
  };

  selectMenu = selectedKeys => {
    let selected = selectedKeys;
    if (selectedKeys[0].indexOf('/user') >= 0) {
      selected = ['/user'];
    }
    return selected;
  };

  render() {
    const { collapsed, menuData, logo, siderTitle } = this.props;
    const { openKeys, selectedKeys } = this.state;
    const selected = this.selectMenu(selectedKeys);
    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={styles.sider}
        width='250'
      >
        <div className={styles.logo} key='logo'>
          <Link to='/'>
            <img src={logo} alt='logo' />
            <h1>{siderTitle}</h1>
          </Link>
        </div>
        <Menu
          theme='dark'
          mode='inline'
          style={{ padding: '16px 0px' }}
          openKeys={openKeys}
          selectedKeys={selected}
          onOpenChange={this.handleOpenSubMenu}
        >
          {this.handleCreateMenu(menuData)}
        </Menu>
      </Sider>
    );
  }
}
