export default {
  namespace: 'operator',
  state: {
    triggerList: [],
    triggerTotal: 0,
    loadingTrigger: false,

    eventsList: [],
    eventsTotal: 0,
    loadingEvents: false,

    spotTypeObj: {},
    spotTypeList: []
  }
};
