export const GUIDE_STATE_ENUM = {
  Show: {
    Name: 'Active',
    DisplayText: '已发布'
  },
  Draft: {
    Name: 'Draft',
    DisplayText: '草稿'
  },
  Deleted: {
    Name: 'Archived',
    DisplayText: '已删除'
  },
  All: {
    Name: '',
    DisplayText: '全部'
  }
};

export const GUIDE_ENUM = {
  Active: 'Active', //发布
  Draft: 'Draft', //草稿
  Archived: 'Archived' //删除
};

export const GUIDE_METHODS = {
  ToUp: {
    Name: 'toUp',
    DisplayText: '上架'
  },
  ToDelete: {
    Name: 'toDelete',
    DisplayText: '删除'
  }
};
