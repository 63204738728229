export const OPPORTUNITY_TYPE = {
  ClinicalObservation: '临床观摩',
  Conference: '学术会议',
  Job: '工作机会',
  Consulting: '国际咨询',
  Internship: '实习机会',
  Speech: '会议演讲',
  Training: '培训进修'
};

export const TIME_LOCATION_REQUIRED_TYPES = ['Conference'];

export const OPPORTUNITY_STATUS = {
  Active: '上架',
  Closed: '删除',
  Draft: '下架'
};

export const OPPORTUNITY_STATUS_ENUMS = {
  Active: 'Active',
  Closed: 'Closed',
  Draft: 'Draft'
};

export const HOSPITAL_SYNC = {
  Yes: '已同步到医院版',
  NO: '未同步到医院版'
}

export const EMAIL_RGE = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

export const SORT_COL = {
  ApplicantCount: '申请人数'
};

export const SORT_DIR = {
  1: '升序'
};

export const PUBLISH_TYPE = {
  Pier88Health: '代发',
  Direct: '机构发布'
};
