import { message } from 'antd';
import { call, put, select } from 'redux-saga/effects';
import api from '@API';
import handleError from '@UTILS/handleError'

const preOrderList = state => state.order.orderList;

export default {
  * getAdminGetOrders({ payload }) {
    try {
      const { Total, Orders } = yield call(api.getAdminGetOrders, payload)
      yield put({
        type: 'setState',
        payload: {
          orderList: Orders,
          orderTotal: Total,
          orderListLoading: false
        }
      })
    } catch (error) {
      handleError(error);
    }
  },
  * setLoading({ payload }) {
    try {
      yield put({
        type: 'setState',
        payload: {
          orderListLoading: payload
        }
      })
    } catch (error) {
      handleError(error);
    }
  },

  * changeOrderStatus({ payload }) {
    const { OrderId, NewStatus } = payload
    const preList = yield select(preOrderList)
    try {
      yield call(api.changeOrderStatus, payload)
      yield put({
        type: 'setState',
        payload: {
          orderList: preList.map(item => (item.p8Id === OrderId ? { ...item, Status: NewStatus } : item))
        }
      })
      message.success('修改成功')
    } catch (error) {
      handleError(error);
    }
  },

  * changeOrderNote({ payload }) {
    const { OrderId, AdminNote } = payload
    const preList = yield select(preOrderList)
    try {
      yield call(api.updateAdminNote, payload)
      yield put({
        type: 'setState',
        payload: {
          orderList: preList.map(item => (item.p8Id === OrderId ? { ...item, AdminNote } : item))
        }
      })
    } catch (error) {
      handleError(error);
    }
  }
}
