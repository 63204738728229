import React, { PureComponent } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, message } from 'antd';
import { getSecurityErrorInfo } from '@UTILS/authority';
import { isHNEnv } from '@UTILS/utils';
import styles from './index.less';

const FormItem = Form.Item;
const LOCK_TIME = 1000 * 60 * 30; // 10min
window.isHNEnv = isHNEnv;
class Login extends PureComponent {
  state = {
    isDisableLogin: false,
    loading: false,
    countTime: 59
  }

  passwordValidator = (rule, value, callback) => {
    if (window.isHNEnv
      && value && !/^(?![a-zA-Z]+$)(?![A-Z\d]+$)(?![A-Z_!@#$%^&*`~()-+=]+$)(?![a-z\d]+$)(?![a-z_!@#$%^&*`~()-+=]+$)(?![\d_!@#$%^&*`~()-+=]+$)[a-zA-Z\d_!@#$%^&*`~()-+=]{8,16}$/.test(value)) {
      callback(new Error('密码为数字，小写字母，大写字母，特殊符号 至少包含三种，长度为 8 - 16位'))
    }
    callback()
  }

  countDown = () => {
    let { countTime } = this.state;
    const timerInterval = setInterval(() => {
      this.setState({ countTime: countTime-- }, () => {
        if (countTime <= -1) {
          clearInterval(timerInterval);
          this.setState({ loading: false, countTime: 59 })
        }
      });
    }, 1000);
  }

  verifiedSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.countTime > 0) {
          this.countDown();
        }
        this.props.onSendSMSCode(values);
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (window.isHNEnv && !values.code) {
          message.error('请输入验证码')
          return;
        }
        this.setState({ isDisableLogin: true })
        const record = getSecurityErrorInfo()
        if (record) {
          const count = +record.split('_')[0]
          const time = +record.split('_')[1]
          if (count >= 5 && new Date().getTime() - time <= LOCK_TIME) {
            message.error('您的密码或其他帐户信息的错误次数过多,帐号锁定10分钟!')
            setTimeout(() => {
              this.setState({ isDisableLogin: false })
            }, LOCK_TIME)
            return
          }
        }
        this.props.onLogin(values);
        setTimeout(() => {
          this.setState({ isDisableLogin: false })
        }, 5000)
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { username, password } = this.props;
    const { isDisableLogin } = this.state
    return (
      <div className={styles.login}>
        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator(username.id || 'username', {
              rules: username.rules || [
                { required: true, message: 'Please input your Username!' },
                { message: '手机号码有误', pattern: /^1[3456789]\d{9}$/ }
              ]
            })(
              <Input
                prefix={
                  <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder='请输入用户名'
                size='large'
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator(password.id || 'password', {
              rules: password.rules || [
                { required: true, message: 'Please input your Password!' },
                { validator: this.passwordValidator }
              ]
            })(
              <Input
                prefix={
                  <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type='password'
                placeholder='请输入密码'
                size='large'
              />
            )}
          </FormItem>
          {window.isHNEnv && (
          <FormItem>
            {getFieldDecorator('code', {
              rules: [{ required: false, message: '请输入验证码!' }],
            })(<Input placeholder='请输入验证码' style={{ width: '130px', marginRight: '110px' }} />)
            }
            <Button loading={this.state.loading} htmlType='submit' onClick={this.verifiedSubmit}>
              {this.state.loading ? `${this.state.countTime}秒` : '发送验证'}
            </Button>
          </FormItem>
          )}
          <FormItem>
            <Button
              type='primary'
              htmlType='submit'
              style={{ width: '100%' }}
              size='large'
              disabled={isDisableLogin}
            >
              登录
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default Form.create()(Login);
