import React from 'react';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import Avatar from '@COMS/Avatar/Avatar';
import styles from './index.less';

const ENUM = 'zh';

export default class GlobalHeader extends React.PureComponent {
  render() {
    const {
      collapsed,
      avatarMenu = [],
      onClickAvatarMenu,
      onCollapseMenu,
      logo,
      currentUser = {}
    } = this.props;
    const { UserPersonal = {}, p8Id, Type } = currentUser;
    const { DisplayName = {} } = UserPersonal;
    const IconComponent = collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
    const menu = (
      <Menu className={styles.menu} onClick={onClickAvatarMenu}>
        {avatarMenu.map(n => (
          <Menu.Item key={n.key} disabled={n.disabled || false}>
            <LogoutOutlined />
            {n.title}
          </Menu.Item>
        ))}
      </Menu>
    );
    return (
      <div className={styles.header}>
        <IconComponent
          className={styles.trigger}
          onClick={onCollapseMenu}
        />
        <div className={styles.right}>
          <Dropdown overlay={menu} placement='bottomRight'>
            <span className={styles.avatar}>
              <Avatar
                userId={p8Id}
                type={Type}
                avatarVersion={p8Id}
                defaultImg={logo}
              />
              <span className={styles.namecls}>{DisplayName[ENUM]}</span>
            </span>
          </Dropdown>
        </div>
      </div>
    );
  }
}
