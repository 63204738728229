import { message } from 'antd';
import { call, put, select } from 'redux-saga/effects';
import api from '@API';
import { COURSE_ENUMS } from '@COMMON/course';
import { LIST_DEFAULT_TAKE } from '@COMMON/constant'
import { mapList } from '@UTILS/utils';
import handleError from '@UTILS/handleError'

const courseList = state => state.course.courseList;

export default {
  * getCourseList({ payload }) {
    try {
      const { Courses, Total } = yield call(api.getCourseList, {
        take: LIST_DEFAULT_TAKE,
        ...payload,
      });
      yield put({
        type: 'setState',
        payload: {
          courseList: Courses,
          courseTotal: Total
        }
      });
    } catch (error) {
      handleError(error);
    }
  },
  * archiveCourse({ payload }) {
    const { CourseId } = payload;
    const list = yield select(courseList);
    try {
      yield call(api.archiveCourse, payload);
      yield put({
        type: 'setState',
        payload: {
          courseList: mapList(list, 'p8Id', CourseId, { Status: COURSE_ENUMS.Archived }),
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * publishCourse({ payload }) {
    const { CourseId } = payload;
    const list = yield select(courseList);
    try {
      yield call(api.publishCourse, payload);
      yield put({
        type: 'setState',
        payload: {
          courseList: mapList(list, 'p8Id', CourseId, { Status: COURSE_ENUMS.Active }),
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * recycleCourse({ payload }) {
    const { CourseId } = payload
    const list = yield select(courseList);
    try {
      yield call(api.recycleCourse, payload)
      message.success('下架成功！')
      yield put({
        type: 'setState',
        payload: {
          courseList: mapList(list, 'p8Id', CourseId, { Status: COURSE_ENUMS.Draft }),
        }
      })
    } catch (error) {
      handleError(error);
    }
  },

  * saveCourse({ payload }) {
    const { p8Id } = payload
    const list = yield select(courseList);
    try {
      yield call(api.saveCourse, payload)
      message.success('视频已上架')
      yield put({
        type: 'setState',
        payload: {
          courseList: mapList(list, 'p8Id', p8Id, { Status: COURSE_ENUMS.Active }),
        }
      })
    } catch (error) {
      handleError(error);
    }
  },

  * exportCourseToFeed({ payload }) {
    try {
      const { CourseId } = payload
      const preList = yield select(courseList)
      yield call(api.exportCourseToFeed, payload)
      yield put({
        type: 'setState',
        payload: {
          courseList: mapList(preList, 'p8Id', CourseId, { FeedItemId: true }),
        }
      })
      message.success('课程同步成功')
    } catch (error) {
      handleError(error)
    }
  }
};
