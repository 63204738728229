import React from 'react';
import { connect } from 'react-redux';
import Login from '@COMS/Login';
import logo from '@ASSETS/logo.png';
import styles from './index.less';
import { getSecurityErrorInfo } from '@UTILS/authority'

class AdminLogin extends React.PureComponent {
  constructor(props) {
    super(props);
    const record = getSecurityErrorInfo()
    if (!record) {
      localStorage.clear();
    }
  }

  handleLogin = values => {
    this.props.dispatch({ type: 'login', payload: { ...values } });
  };

  handleSMSCode = values => {
    this.props.dispatch({ type: 'smsCode', payload: { ...values } });
  };

  render() {
    return (
      <div className={styles.loginBox}>
        <div className={styles.top}>
          <img src={logo} alt='logo' />
          <h1>MedLinc admin</h1>
        </div>
        <div className={styles.logo}>
          <Login
            username={{
              id: 'username'
            }}
            password={{
              id: 'password'
            }}
            onLogin={this.handleLogin}
            onSendSMSCode={this.handleSMSCode}
          />
        </div>
      </div>
    );
  }
}

export default connect()(AdminLogin);
