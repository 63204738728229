import { call, put, select, all } from 'redux-saga/effects';
import api from '@API';
import { LanguageEnum } from '@COMMON/constant';
import handleError from '@UTILS/handleError'

const DEFAULT_TAKE = 20;
const getGroupList = state => state.commonData.commonGroupList;
const getTopicList = state => state.commonData.topicList;
const getSpecialtyList = state => state.commonData.specialtyList;
const getDepartMentList = state => state.commonData.departMentList;
const getIcdList = state => state.commonData.icdList;
const getOriginalList = state => state.commonData.commonOriginalList;
const getCountries = state => state.commonData.countries;

export default {
  * getGroupLookup({ payload }) {
    try {
      const { skip = 0, take = DEFAULT_TAKE } = payload;
      const initGroupList = yield call(api.getGroupLookup, payload);
      const groupList = initGroupList.map(item => ({
        key: item.Id,
        BusinessType: item.BusinessType,
        value: item.Name.zh
      }));
      const lastGroupList = yield select(getGroupList);
      yield put({
        type: 'setState',
        payload: {
          commonGroupList:
            skip === 0 ? groupList : lastGroupList.concat(groupList),
          calLoadMoreGroup: groupList.length >= take,
          groupSkip: skip
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getTopic({ payload }) {
    try {
      const { skip = 0, take = DEFAULT_TAKE } = payload;
      const list = yield call(api.getTopic, payload);
      const lastList = yield select(getTopicList);
      yield put({
        type: 'setState',
        payload: {
          topicList: skip === 0 ? list : lastList.concat(list),
          canLoadMoreTopic: list.length >= take,
          topicSkip: skip
        }
      });
    } catch (error) {
      handleError(error);
    }
  },
  * getSpecialtyList({ payload }) {
    try {
      const { skip = 0, take = DEFAULT_TAKE } = payload;
      const list = yield call(api.getSpecialtyList, payload);
      const lastList = yield select(getSpecialtyList);
      yield put({
        type: 'setState',
        payload: {
          specialtyList: skip === 0 ? list : lastList.concat(list),
          canLoadMoreSpecialty: list.length >= take,
          specialtySkip: skip
        }
      });
    } catch (error) {
      handleError(error);
    }
  },
  * getIcdList({ payload }) {
    try {
      const { skip = 0, take = DEFAULT_TAKE } = payload;
      const list = yield call(api.getIcdList, payload);
      const lastList = yield select(getIcdList);
      yield put({
        type: 'setState',
        payload: {
          icdList: skip === 0 ? list : lastList.concat(list),
          canLoadMoreIcd: list.length >= take,
          icdSkip: skip
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getDepartMents({ payload }) {
    try {
      const { skip = 0, take = DEFAULT_TAKE } = payload;
      const list = yield call(api.getDepartmentsByName, payload);
      const lastList = yield select(getDepartMentList);
      yield put({
        type: 'setState',
        payload: {
          departMentList: skip === 0 ? list : lastList.concat(list),
          canLoadMoreDepartMent: list.length >= take,
          departMentSkip: skip
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getOriginalList({ payload }) {
    const { skip, take = DEFAULT_TAKE } = payload;
    try {
      const { Tags: initOriginalList } = yield call(
        api.getOriginalTags,
        payload
      );
      const mapOriginalList = initOriginalList.map(item => ({
        key: item?.TagId,
        type: item?.TagType,
        value: item?.TagName[LanguageEnum.zh]
      }));
      const lastList = yield select(getOriginalList);
      yield put({
        type: 'setState',
        payload: {
          commonOriginalList:
            skip === 0
              ? mapOriginalList
              : lastList.concat(mapOriginalList),
          canLoadOriginalList: mapOriginalList.length >= take,
          commonOriginalListSkip: skip
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getEmployeeSize() {
    try {
      const list = yield call(api.getEmployeeSize);
      yield put({
        type: 'setState',
        payload: {
          employeeSize: list
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getCountries({ payload = {} }) {
    try {
      const { skip = 0, take = 50 } = payload
      const { Countries, Total } = yield call(api.getAllCountries, {
        skip,
        take,
      });
      const countries = yield select(getCountries)
      const newList = skip === 0 ? Countries : [...countries, ...Countries]
      yield put({
        type: 'setState',
        payload: {
          countries: newList,
          canLoadCountry: newList.length < Total,
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getChinaProvinces() {
    try {
      const list = yield call(api.getChinaProvinces);
      yield put({
        type: 'setState',
        payload: {
          ChinaProvinces: list
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getUSStates() {
    try {
      const list = yield call(api.getUSStates);
      yield put({
        type: 'setState',
        payload: {
          USStates: list
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getCities({ payload }) {
    try {
      const list = yield call(api.getCities, payload);
      yield put({
        type: 'setState',
        payload: {
          cities: list
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getBusinessType() {
    try {
      const list = yield call(api.getBusinessType);
      yield put({
        type: 'setState',
        payload: {
          businessTypeList: list
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getSpecialties() {
    try {
      const list = yield call(api.getSpecialties);
      yield put({
        type: 'setState',
        payload: {
          specialtiesList: list
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getDegrees() {
    try {
      const [ChinaDegree, USADegree] = yield all([call(api.getChinaMainDegrees), call(api.getUSAMainDegrees)])
      yield put({
        type: 'setState',
        payload: {
          ChinaDegree,
          USADegree,
        }
      })
    } catch (error) {
      handleError(error);
    }
  },

  * getDepartments() {
    try {
      const list = yield call(api.getDepartments)
      yield put({
        type: 'setState',
        payload: {
          departmentList: list,
        }
      })
    } catch (error) {
      handleError(error);
    }
  },

  * getPositions() {
    try {
      const list = yield call(api.getPositions)
      yield put({
        type: 'setState',
        payload: {
          positions: list,
        }
      })
    } catch (error) {
      handleError(error);
    }
  },
};
