export const ACTIVITY_TYPE = {
  Create: 'Create',
  Comment: 'Comment',
  Like: 'Like',
  Share: 'Share'
};

export const ENTITY_TYPE = {
  FriendSuggests: 'FriendSuggests',
  Post: 'Post',
  OpportunitySuggests: 'OpportunitySuggests',
  GroupSuggests: 'GroupSuggests',
  Article: 'Article',
};

export const FEED_TYPE = {
  FEED: 'feed',
  ARTICLE: 'article',
}

export const Draft = 'Draft'

export const FEED_STATUS = {
  DRAFT: 'Draft',
  ACTIVE: 'Active',
  PENDING: 'Pending',
  ARCHIVED: 'Archived',
  BLOCKED: 'Blocked',
}

export const FEED_STATUS_ENUMS = {
  Draft: '草稿',
  Active: '已发布',
  Archived: '已删除',
  Pending: '定时发布',
}

export const SORTED_BY = {
  ClickCount: '阅读数量',
  LikeCount: '点赞数量',
  CommentCount: '评论数量'
}

export const SORTED_ORDER = {
  up: '升序'
}

export const VIDEO_EXT = ['mp4', 'wmv', 'avi', 'rmvb', 'mov', 'flv', 'rm']

export const AUDIO_EXT = ['mp3', 'wma', 'wav', 'amr', 'm4a']

export const VIDEO_TYPE = [
  'video/mp4',
  'video/wmv',
  'video/avi',
  'video/rmvb',
  'video/mov',
  'video/flv',
  'video/rm'
]

export const AUDIO_TYPE = [
  'audio/mp3',
  'audio/wma',
  'audio/wav',
  'audio/amr',
  'audio/m4a',
]

export const EXPORT_TIP = '将生成一条相关动态，是否同步到动态？'

export const FEED_ENTITYTYPE = {
  Post: '动态',
  Opportunity: '机会',
  Guide: '指南',
  Course: '课程',
  MedWord: '词汇',
  Meetup: '会议',
}

export const FEEDITEM = 'FeedItem'

export const COPYRIGHT_TYPE = {
  Default: 'Default',
  Reprinted: 'Reprinted',
  Original: 'Original',
}

export const COPYRIGHT_KEY_TYPE = {
  Original: '原创',
  Reprinted: '转载',
  Default: '无来源',
}

export const COPYRIGHT_LABEL_TYPE = {
  Reprinted: '转载声明',
  Original: '原创声明',
}

export const STATEMENT_TYPE = {
  Reprinted: '出于分享、交流、共同学习的目的转载文本，版权归原作者。如需转载请自行联系原作者。如有问题，敬请联系平台删除。衷心感谢！',
  Original: '本文为医者无界原创或编译文章，转载时务必注明文章作者和"医者无界"。对于不尊重原创的行为，医者无界保留追责权利。',
}
