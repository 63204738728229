export default {
  namespace: 'group',
  state: {
    groupList: [],
    loadingGroupList: false,
    totalGroupList: 0,

    groupMemberList: [],
    loadingGroupMember: false,
    totalGroupMember: 0,

    maintainerList: [],
    loadingMaintainerList: false,
    maintainerTotle: 0,
    denyReason: []
  }
};
