import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { transformReducers, transformSagas } from 'redux-helps';
import rootReducer from '../reducers';
import { common, data } from '../reducers/search';
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();

export default function configStore(history) {
  const store = createStore(
    combineReducers({
      ...transformReducers(rootReducer),
      common,
      data,
      router: connectRouter(history)
    }),
    applyMiddleware(routerMiddleware(history), sagaMiddleware)
  );
  store.runSaga = () => sagaMiddleware.run(transformSagas(rootSaga));
  return store;
}
