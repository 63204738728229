export default {
  namespace: 'feed',
  state: {
    hospitalFeedLists: [],
    hospitalFeedTotal: 0,
    loadingHospitalFeed: false,

    hospotalLists: [],
    canLoadHospital: true,
    hospitalSkip: 0,
    // userFeed
    contentList: [],
    loadingContentList: false,
    contentListTotal: 0,
    // ownFeed
    ownContentList: [],
    loadingOwnContentList: false,
    ownContentTotal: 0,

    articleList: [],
    articleTotal: 0,
    loadingArticleFeed: false,

    feedDetail: {},
    loadingFeedDetail: true,
    confirmLikeLoading: false,
    closeLikeModal: false,
    likeList: [],
    closeCommentModal: false,
    confirmCommentLoading: false,
    commentList: [],
    commentSkip: 0,
    canLoadComment: false
  }
};
