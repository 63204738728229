import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  moduleNameSelector,
  queryObjSelector
} from '@UTILS/selectors';
import * as actions from '../actions';
// import api as services from '@API'

function* showError(error) {
  console.log('Show Error ', error);
}

function* smartQuery(action) {
  try {
    const moduleName = yield select(moduleNameSelector);
    const queryObj = yield select(queryObjSelector(moduleName));
    queryObj.param = action.payload;
    //reset pageNum
    queryObj.pageNum = 1;
    yield put(actions.smartSetQueryObj(queryObj, moduleName));
    yield put(actions.smartSelect());
  } catch (error) {
    console.log(error);
  }
}

function* smartSelect() {
  try {
    const moduleName = yield select(moduleNameSelector);
    // show loading
    // yield put(actions.smartShowLoading());
    const data = {}; //yield call(services.smartSelect, moduleName, queryObj);
    if (data.success) yield put(actions.smartSelectSuccess(data, moduleName));
    // hide loading
    // yield put(actions.smartHideLoading());
    // pass saga response to external project
    yield put(
      actions.smartResMessage({
        ...data,
        mode: 'select'
      })
    );
  } catch (error) {
    // hide loading
    // yield put(actions.smartHideLoading());
    yield call(showError, error);
  }
}

function* smartPageNumChange() {
  try {
    // if (action.type === actions.SMART_PAGENUM_CHANGE) {
    //   queryObj.pageNum = action.payload;
    // } else {
    //   queryObj.pageSize = action.payload;
    //   queryObj.pageNum = 1;
    // }
    // yield put(actions.smartSetQueryObj(queryObj, moduleName));
    yield put(actions.smartSelect());
  } catch (error) {
    yield call(showError, error);
  }
}

function* smartClearQueryParam() {
  try {
    yield put(actions.clearModuleQueryParam());
  } catch (error) {
    yield call(showError, error);
  }
}

export default function* rootSaga() {
  yield [
    takeLatest(actions.SMART_QUERY_REQUEST, smartQuery),
    takeLatest(actions.SMART_SELECT_REQUEST, smartSelect),
    takeLatest(actions.SMART_PAGENUM_CHANGE, smartPageNumChange),
    takeLatest(actions.CLEAR_QUERY_PARAM, smartClearQueryParam)
  ];
}
