import { message } from 'antd';
import { call, put, select } from 'redux-saga/effects';
import api from '@API';
import { TRIGGER_STATUS_ENUMS } from '@COMMON/operator';
import { COURSE_ENUMS } from '@COMMON/course';
import { LIST_DEFAULT_TAKE } from '@COMMON/constant';
import handleError from '@UTILS/handleError'

const getTriggerList = state => state.operator.triggerList;
const getEventsList = state => state.operator.eventsList;

export default {
  * getTriggers({ payload }) {
    try {
      const { Total, Triggers } = yield call(api.getTriggers, {
        take: LIST_DEFAULT_TAKE,
        ...payload,
      });
      yield put({
        type: 'setState',
        payload: {
          triggerList: Triggers,
          triggerTotal: Total,
          loadingTrigger: false
        }
      });
    } catch (error) {
      handleError(error);
      yield put({
        type: 'setState',
        payload: {
          loadingUserList: false
        }
      });
    }
  },

  * activeTrigger({ payload }) {
    try {
      const { TriggerId } = payload;
      yield call(api.activeTrigger, payload);
      message.success('创建推送申请成功！');
      const list = yield select(getTriggerList);
      yield put({
        type: 'setState',
        payload: {
          triggerList: list.map(item => (item.p8Id === TriggerId
            ? {
              ...item,
              Status: TRIGGER_STATUS_ENUMS.Pending,
              DeliveryDate: Date.now()
            }
            : item))
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * deactivateTrigger({ payload }) {
    try {
      const { TriggerId } = payload;
      yield call(api.deactivateTrigger, payload);
      message.success('已取消推送！');
      const list = yield select(getTriggerList);
      yield put({
        type: 'setState',
        payload: {
          triggerList: list.map(item => (item.p8Id === TriggerId
            ? {
              ...item,
              Status: TRIGGER_STATUS_ENUMS.Draft
            }
            : item))
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * archiveTrigger({ payload }) {
    try {
      const { TriggerId } = payload;
      yield call(api.archiveTrigger, payload);
      message.success('删除成功！');
      const list = yield select(getTriggerList);
      yield put({
        type: 'setState',
        payload: {
          triggerList: list.filter(item => item.p8Id !== TriggerId)
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getEvents({ payload }) {
    try {
      const { Events, Total } = yield call(api.getEvents, payload)
      yield put({
        type: 'setState',
        payload: {
          eventsList: Events,
          eventsTotal: Total,
          loadingEvents: false
        }
      })
    } catch (error) {
      handleError(error)
    }
  },

  * recycleEvents({ payload }) {
    try {
      const { EventId } = payload
      const list = yield select(getEventsList)
      yield call(api.recycleEvents, payload)
      yield put({
        type: 'setState',
        payload: {
          eventsList: list.map(item => (item.EventId === EventId ? { ...item, Status: COURSE_ENUMS.Draft } : item))
        }
      })
    } catch (error) {
      handleError(error)
    }
  },

  * archiveEvents({ payload }) {
    try {
      const { EventId } = payload
      const list = yield select(getEventsList)
      yield call(api.archiveEvents, payload)
      yield put({
        type: 'setState',
        payload: {
          eventsList: list.map(item => (item.EventId === EventId ? { ...item, Status: COURSE_ENUMS.Archived } : item))
        }
      })
    } catch (error) {
      handleError(error)
    }
  },

  * getSpotTypes() {
    try {
      const spotTypeList = yield call(api.getSpotTypes)
      const spotTypeObj = spotTypeList.reduce((pre, next) => {
        const o = pre
        o[next.Name] = next.DisplayText
        return o
      }, {})
      yield put({
        type: 'setState',
        payload: {
          spotTypeObj,
          spotTypeList
        }
      })
    } catch (error) {
      handleError(error)
    }
  }
};
