import { put, select, all } from 'redux-saga/effects';

const getShowDepartData = state => state.tag.showDepartData;
const getShowIcdData = state => state.tag.showIcdData;
const getShowAdminDefinedData = state => state.tag.showAdminDefinedData;
const getShowCircleData = state => state.tag.showCircleData;

const getTransferIcdMsg = state => state.tag.transferIcdMsg;
const getTransferDeptMsg = state => state.tag.transferDeptMsg;
const getTransferAdminDefinedMsg = state => state.tag.transferAdminDefinedMsg;
const getTransferCircleMsg = state => state.tag.transferCircleMsg;

const getIcdMsgUpdate = state => state.tag.isIcdMsgUpdate;
const getDeptMsgUpdate = state => state.tag.isDeptMsgUpdate;
const getAdminDefinedMsgUpdate = state => state.tag.isAdminDefinedMsgUpdate;

const judgeCanUpdateTags = (isIcdMsgUpdate = false, isDeptMsgUpdate = false, isAdminDefinedMsgUpdate = false, isCircleMsgUpdate = false) => {
  return isIcdMsgUpdate || isDeptMsgUpdate || isAdminDefinedMsgUpdate || isCircleMsgUpdate;
};
export default {
  * initTagData() {
    yield put({
      type: 'setState',
      payload: {
        selectTagListModalVisible: false,
        showDepartData: [],
        showIcdData: [],
        showAdminDefinedData: [],
        showCircleData: null,
        transferIcdMsg: [],
        transferDeptMsg: [],
        transferAdminDefinedMsg: [],
        transferCircleMsg: null,
      }
    });
  },
  * setTagModalVisiable({ selectTagListModalVisible }) {
    yield put({
      type: 'setState',
      payload: {
        selectTagListModalVisible
      }
    });
  },
  * setShowTagData({ showTagData }) {
    const {
      showDepartData = [],
      showIcdData = [],
      showAdminDefinedData = [],
    } = showTagData;
    yield put({
      type: 'setState',
      payload: {
        showDepartData,
        showIcdData,
        showAdminDefinedData,
      }
    });
  },
  * setResetCanUpdateTags() {
    yield put({
      type: 'setState',
      payload: {
        isIcdMsgUpdate: false,
        isDeptMsgUpdate: false,
        isAdminDefinedMsgUpdate: false,
        isCircleMsgUpdate: false,
        canUpdateTags: false,
      }
    });
  },
  * setTransferIcdMsg({ transferIcdMsg }) {
    yield put({
      type: 'setState',
      payload: {
        transferIcdMsg,
      }
    });
  },
  * setTransferDeptMsg({ transferDeptMsg }) {
    yield put({
      type: 'setState',
      payload: {
        transferDeptMsg,
      }
    });
  },
  * setTransferAdminDefinedMsg({ transferAdminDefinedMsg }) {
    yield put({
      type: 'setState',
      payload: {
        transferAdminDefinedMsg,
      }
    });
  },
  * setTransferCircleMsg({ transferCircleMsg }) {
    yield put({
      type: 'setState',
      payload: {
        transferCircleMsg,
      }
    });
  },
  * setTransferIcdAndChangeMsg({ payload }) {
    const {
      transferIcdMsg,
      isIcdMsgUpdate
    } = payload;
    const [isDeptMsgUpdate, isAdminDefinedMsgUpdate] = yield all([select(getDeptMsgUpdate), select(getAdminDefinedMsgUpdate)]);
    const canUpdateTags = judgeCanUpdateTags(isIcdMsgUpdate, isDeptMsgUpdate, isAdminDefinedMsgUpdate);
    yield put({
      type: 'setState',
      payload: {
        isIcdMsgUpdate,
        transferIcdMsg,
        canUpdateTags
      }
    });
  },
  * setTransferDeptAndChangeMsg({ payload }) {
    const {
      transferDeptMsg,
      isDeptMsgUpdate
    } = payload;
    const [isIcdMsgUpdate, isAdminDefinedMsgUpdate] = yield all([select(getIcdMsgUpdate), select(getAdminDefinedMsgUpdate)]);
    const canUpdateTags = judgeCanUpdateTags(isIcdMsgUpdate, isDeptMsgUpdate, isAdminDefinedMsgUpdate);
    yield put({
      type: 'setState',
      payload: {
        isDeptMsgUpdate,
        transferDeptMsg,
        canUpdateTags
      }
    });
  },
  * setTransferAdminDefinedAndChangeMsg({ payload }) {
    const {
      transferDeptMsg,
      isAdminDefinedMsgUpdate
    } = payload;
    const [isIcdMsgUpdate, isDeptMsgUpdate] = yield all([select(getIcdMsgUpdate), select(getDeptMsgUpdate)]);
    const canUpdateTags = judgeCanUpdateTags(isIcdMsgUpdate, isDeptMsgUpdate, isAdminDefinedMsgUpdate);
    yield put({
      type: 'setState',
      payload: {
        isAdminDefinedMsgUpdate,
        transferDeptMsg,
        canUpdateTags
      }
    });
  },
  * setTransferCircleAndChangeMsg({ payload }) {
    const {
      transferCircleMsg,
      isCircleMsgUpdate
    } = payload;
    const [isIcdMsgUpdate, isDeptMsgUpdate, isAdminDefinedMsgUpdate] = yield all([select(getIcdMsgUpdate), select(getDeptMsgUpdate), select(getAdminDefinedMsgUpdate)]);
    const canUpdateTags = judgeCanUpdateTags(isIcdMsgUpdate, isDeptMsgUpdate, isAdminDefinedMsgUpdate, isCircleMsgUpdate);
    yield put({
      type: 'setState',
      payload: {
        isCircleMsgUpdate,
        transferCircleMsg,
        canUpdateTags
      }
    });
  },
  * updateSaveTagDataAndClose({ filterTagIds }) {
    const {
      DeptTagIds = [],
      IcdTagIds = [],
      AdminDefinedTagIds = [],
      CircleId = ''
    } = filterTagIds;
    const [transferIcdMsg, transferDeptMsg, transferAdminDefinedMsg, transferCircleMsg] = yield all([select(getTransferIcdMsg), select(getTransferDeptMsg), select(getTransferAdminDefinedMsg), select(getTransferCircleMsg)]);
    const updateDeptMsg = transferDeptMsg && transferDeptMsg.filter(dept => DeptTagIds.includes(dept?.TagId));
    const updateIcdMsg = transferIcdMsg && transferIcdMsg.filter(icd => IcdTagIds.includes(icd?.TagId));
    const updateAdminDefinedMsg = transferAdminDefinedMsg && transferAdminDefinedMsg.filter(admin => AdminDefinedTagIds.includes(admin?.TagId));
    const updateCircleMsg = transferCircleMsg?.CircleId === CircleId ? transferCircleMsg : null;
    yield put({
      type: 'setState',
      payload: {
        showDepartData: updateDeptMsg,
        showIcdData: updateIcdMsg,
        showAdminDefinedData: updateAdminDefinedMsg,
        showCircleData: updateCircleMsg,
        selectTagListModalVisible: false
      }
    });
  },
  * removeTag({ tagId }) {
    const [showDepartData, showIcdData, showAdminDefinedData, showCircleData] = yield all([select(getShowDepartData), select(getShowIcdData), select(getShowAdminDefinedData), select(getShowCircleData)]);
    if (tagId) {
      yield put({
        type: 'setState',
        payload: {
          showDepartData: showDepartData.filter(depart => depart?.TagId !== tagId),
          showIcdData: showIcdData.filter(icd => icd?.TagId !== tagId),
          showAdminDefinedData: showAdminDefinedData.filter(admin => admin?.TagId !== tagId),
          showCircleData: showCircleData?.CircleId !== tagId ? showCircleData : null
        }
      });
    }
  }
}