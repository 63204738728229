import { message } from 'antd';
import { call, put, select } from 'redux-saga/effects';
import { GUIDE_ENUM } from '@COMMON/guide';
import api from '@API';
import { mapList } from '@UTILS/utils';
import handleError from '@UTILS/handleError'

const guideList = state => state.guide.guideList

export default {
  * passGuideEditInfo({ payload }) {
    const { initDepartIds, initSpecialtyIds, initIcdIds } = payload;
    yield put({
      type: 'setState',
      payload: {
        initDepartIds,
        initSpecialtyIds,
        initIcdIds
      }
    });
  },

  * passDrawerGuideId({ payload }) {
    const { openDrawerGuideId } = payload;
    yield put({
      type: 'setState',
      payload: {
        openDrawerGuideId
      }
    });
  },

  * exportGuideToFeed({ payload }) {
    try {
      const { GuideId } = payload
      yield call(api.exportGuideToFeed, payload)
      const list = yield select(guideList);
      yield put({
        type: 'setState',
        payload: {
          guideList: mapList(list, 'p8Id', GuideId, { FeedItemId: true })
        }
      })
      message.success('指南同步成功')
    } catch (error) {
      handleError(`推荐同步失败:${error.message || error}`)
    }
  },

  * getGuideList({ payload }) {
    try {
      const { Guides, Total } = yield call(api.getAdminGuideList, payload)
      yield put({
        type: 'setState',
        payload: {
          guideList: Guides,
          totalGuideList: Total,
          loadingGuideList: false
        }
      })
    } catch (error) {
      handleError(error)
    }
  },

  * recycleGuide({ payload }) {
    try {
      const { GuideId } = payload
      const preList = yield select(guideList)
      yield call(api.recycleGuide, payload)
      yield put({
        type: 'setState',
        payload: {
          guideList: mapList(preList, 'p8Id', GuideId, { Status: GUIDE_ENUM.Draft })
        }
      })
      message.success('指南下架成功')
    } catch (error) {
      message(`下架指南失败:${error.message || error}`)
    }
  },

  * saveGuide({ payload }) {
    try {
      const { p8Id } = payload
      const preList = yield select(guideList)
      yield call(api.saveGuideData, payload)
      yield put({
        type: 'setState',
        payload: {
          guideList: mapList(preList, 'p8Id', p8Id, { Status: GUIDE_ENUM.Active })
        }
      })
      message.success('指南已上架')
    } catch (error) {
      message(`上架指南失败:${error.message || error}`)
    }
  },

  * archiveGuide({ payload }) {
    try {
      const { GuideId } = payload
      const preList = yield select(guideList)
      yield call(api.archiveGuide, payload)
      yield put({
        type: 'setState',
        payload: {
          guideList: mapList(preList, 'p8Id', GuideId, { Status: GUIDE_ENUM.Archived })
        }
      })
      message.success('指南删除成功')
    } catch (error) {
      handleError(`删除指南失败:${error.message || error}`)
    }
  }
};
