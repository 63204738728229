import { message } from 'antd';
import { put, call } from 'redux-saga/effects';
import {
  setToken,
  setLastLoginTime,
  setStsToken,
  removeSecurityErrorInfo,
} from '@UTILS/authority';
import handleError, { securityStrategyError } from '@UTILS/handleError'
import api from '@API';

export default {
  * login({ payload }) {
    try {
      const { username, password, code } = payload;
      const data = {
        UserName: username,
        Password: password,
        SMSCode: code
      };
      const user = yield call(api.signin, data);
      yield put({
        type: 'setState',
        payload: {
          currentUser: user
        }
      });
      setToken(user.UserToken, new Date().getTime());
      setLastLoginTime(new Date().getTime());
      setStsToken({});
      window.location.href = '/'
      message.success('登录成功');
      removeSecurityErrorInfo();
    } catch (error) {
      securityStrategyError(error);
      handleError(error);
    }
  },

  * smsCode({ payload }) {
    try {
      const { username, password } = payload;
      const data = {
        UserName: username,
        Password: password
      };
      yield call(api.smsCode, data);
      message.success('验证码发送成功');
    } catch (error) {
      handleError(error);
    }
  },

  * logout() {
    try {
      yield call(api.logout)
      localStorage.clear();
      window.location.href = '/login'
      message.success('登出成功');
    } catch (e) {
      handleError(e);
    }
  }
};
