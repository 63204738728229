import { message } from 'antd';
import { call, put } from 'redux-saga/effects';
import api from '@API';
import handleError from '@UTILS/handleError'

export default {
  * getCrcList({ payload }) {
    try {
      yield put({
        type: 'setState',
        payload: {
          loadingList: true,
        }
      });
      const result = yield call(api.getCrcList, {
        ...payload,
      });
      yield put({
        type: 'setState',
        payload: {
          crcList: result.CrcList,
          listTotal: result.Total,
          loadingList: false,
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

};
