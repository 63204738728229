import React from 'react';
import { Switch, Redirect } from 'react-router';
import { CopyrightOutlined } from '@ant-design/icons';
import { Layout, message } from 'antd';
import { connect } from 'react-redux';
import GlobalHeader from '@COMS/GlobalHeader';
import GlobalFooter from '@COMS/GlobalFooter';
import SiderMenu from '@COMS/SiderMenu';
import logo from '@ASSETS/logo.png';
import { getMenuData, getRouterData } from '@UTILS/utils';
import FancyRoute from '@COMS/FancyRoute';
import CustomBreadcrumb from '@COMS/CustomBreadcrumb';
import getRouterMapping from '@COMMON/router';
import IdleTimer from '@UTILS/IdleTimer';

const { Header, Footer, Content } = Layout;
const avatarMenu = [
  {
    title: '退出登录',
    key: 'logout',
  }
];
const siderTitle = 'MedLinc admin';

class BasicLayout extends React.PureComponent {
  state = {
    collapsed: false,
    // isTimeout: false
  };

  componentDidMount() {
    this.props.dispatch({
      type: 'getCurrentUser'
    });
    this.props.dispatch({
      type: 'getCountries'
    });
    this.props.dispatch({ type: 'getSpecialties' });
    this.props.dispatch({ type: 'getSpotTypes' })
    if (window.isHNEnv) {
      this.timer = new IdleTimer({
        timeout: 60 * 30, //expire after 30 min
        onTimeout: () => {
          message.warning('会话超时，请重新登录');
          // this.setState({ isTimeOut: true });
          this.props.dispatch({ type: 'logout' });
        },
        onExpired: () => {
          message.warning('会话超时，请重新登录');
          // this.setState({ isTimeOut: true });
          this.props.dispatch({ type: 'logout' });
        }
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer && this.timer.cleanUp();
  }

  handleCollapseMenu = () => {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  };

  handleClicktAvatarMenu = ({ key }) => {
    if (key === 'logout') {
      this.props.dispatch({ type: 'logout' });
    }
  };

  render() {
    const { collapsed } = this.state;
    const { location } = this.props;
    const { currentUser } = this.props.user;
    const { UserContext = {} } = currentUser;
    const { PermissionsHash = {}, MemberRoleInGroup } = UserContext;
    const routerList = getRouterMapping(PermissionsHash, MemberRoleInGroup);
    return (
      <Layout>
        <SiderMenu
          collapsed={collapsed}
          menuData={getMenuData(routerList)}
          location={location}
          logo={logo}
          siderTitle={siderTitle}
        />
        <Layout>
          <Header style={{ background: '#fff', padding: 0 }}>
            <GlobalHeader
              collapsed={collapsed}
              currentUser={currentUser}
              onClickAvatarMenu={this.handleClicktAvatarMenu}
              onCollapseMenu={this.handleCollapseMenu}
              avatarMenu={avatarMenu}
              logo={logo}
            />
          </Header>
          <Content style={{ margin: '20px 24px 0', height: '100%' }}>
            <CustomBreadcrumb routerList={getRouterData(routerList)} />
            <Switch>
              <Redirect exact from='/' to='/main' />
              {getRouterData(routerList).map(item => (
                <FancyRoute
                  key={item.key}
                  path={item.path}
                  component={item.component}
                  exact={item.exact}
                />
              ))}
              {/* <FancyRoute render={NotFound} /> */}
            </Switch>
          </Content>
          <Footer style={{ padding: 0 }}>
            <GlobalFooter
              copyright={(
                <React.Fragment>
                  Copyright
                  {' '}
                  <CopyrightOutlined />
                  {' '}
2021 MedLinc
                </React.Fragment>
)}
            />
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default connect(({ user }) => ({ user }))(BasicLayout);
