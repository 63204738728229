export const SMART_SET_MODULENAME = Symbol('SMART_SET_MODULENAME');
export const smartSetModuleName = payload => ({
  type: SMART_SET_MODULENAME,
  payload
});

export const SMART_QUERY_REQUEST = Symbol('SMART_QUERY_REQUEST');
export const SMART_QUERY_SUCCESS = Symbol('SMART_QUERY_SUCCESS');
export const smartQuery = payload => ({
  type: SMART_QUERY_REQUEST,
  payload
});
export const smartQuerySucess = payload => ({
  type: SMART_QUERY_SUCCESS,
  payload
});

export const SMART_SELECT_REQUEST = Symbol('SMART_SELECT_REQUEST');
export const SMART_SELECT_SUCCESS = Symbol('SMART_SELECT_SUCCESS');
export const smartSelect = () => ({
  type: SMART_SELECT_REQUEST
});
export const smartSelectSuccess = (payload, moduleName) => ({
  type: SMART_SELECT_SUCCESS,
  payload,
  moduleName
});

export const SMART_PAGENUM_CHANGE = Symbol('SMART_PAGENUM_CHANGE');
export const smartPageNumChange = payload => ({
  type: SMART_PAGENUM_CHANGE,
  payload
});

export const SMART_SET_QUERYOBJ = Symbol('SMART_SET_QUERYOBJECT');
export const smartSetQueryObj = (payload, moduleName) => ({
  type: SMART_SET_QUERYOBJ,
  payload,
  moduleName
});

export const SMART_RES_MESSAGE = Symbol('SMART_RES_MESSAGE');
export const smartResMessage = payload => ({
  type: SMART_RES_MESSAGE,
  payload
});

export const CLEAR_MODUEL_QUERY_PARAM = Symbol('CLEAR_MODUEL_QUERY_PARAM');
export const clearModuleQueryParam = moduleName => ({
  type: CLEAR_MODUEL_QUERY_PARAM,
  moduleName
});

export const CLEAR_QUERY_PARAM = Symbol('CLEAR_QUERY_PARAM');
export const clearQueryParam = () => ({
  type: CLEAR_QUERY_PARAM
});
