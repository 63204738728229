export default {
  namespace: 'tag',
  state: {
    selectTagListModalVisible: false,
    showDepartData: [],
    showIcdData: [],
    showAdminDefinedData: [],
    showCircleData: [],
    transferIcdMsg: [],
    transferDeptMsg: [],
    transferAdminDefinedMsg: [],
    transferCircleMsg: [],
    isIcdMsgUpdate: false,
    isDeptMsgUpdate: false,
    isAdminDefinedMsgUpdate: false,
    isCircleMsgUpdate: false,
    canUpdateTags: false
  }
};