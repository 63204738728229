import React from 'react';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router'
import zhCN from 'antd/lib/locale-provider/zh_CN';
import { ConfigProvider, message } from 'antd';

// import createHistory from 'history/createHashHistory';
// user BrowserHistory
import { createBrowserHistory } from 'history';

// import { I18nextProvider } from 'react-i18next';
// import { getI18nInstance, initI18next } from '@I18N/i18n';

import AuthorityRoute from '@COMS/AuthorityRoute';
import BasicLayout from '@LAYOUT/BasicLayout';
import LoginLayout from '@LAYOUT/LoginLayout';
import configStore from '../redux/store';

const history = createBrowserHistory();

const store = configStore(history);
store.runSaga();

function RouterConfig() {
  message.config({
    maxCount: 1
  });
  return (
    <ConfigProvider locale={zhCN}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path='/login' component={LoginLayout} />
            <AuthorityRoute
              path='/'
              render={props => <BasicLayout {...props} />}
              authority={['admin', 'guest', '15757176956']}
              redirectPath='/login'
            />
          </Switch>
        </ConnectedRouter>
      </Provider>
    </ConfigProvider>
  );
}

export default RouterConfig;
