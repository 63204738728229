import { message } from 'antd';
import { call, put, select } from 'redux-saga/effects';
import api from '@API';
import { UserStatusEnums, KOL_TYPE, KOL_IDENTITIES } from '@COMMON/constant';
import handleError from '@UTILS/handleError'

const getUserList = state => state.user.userList;

export default {
  * getCurrentUser() {
    try {
      const user = yield call(api.getCurrentUser);
      yield put({
        type: 'setState',
        payload: {
          currentUser: user
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getUserList({ payload }) {
    try {
      yield put({
        type: 'setState',
        payload: {
          loadingUserList: true,
        }
      });
      const [{ Users }, Total] = yield [
        call(api.getUserList, payload),
        call(api.countUsers, payload)
      ];
      yield put({
        type: 'setState',
        payload: {
          userList: Users,
          loadingUserList: false,
          userListTotal: Total
        }
      });
    } catch (error) {
      handleError(error);
      yield put({
        type: 'setState',
        payload: {
          loadingUserList: false
        }
      });
    }
  },

  * getInvitedUsers({ payload }) {
    try {
      yield put({
        type: 'setState',
        payload: {
          loadingInvitedUserList: true,
        }
      });
      const { Users, Total } = yield call(api.getInvitedUsers, payload);
      yield put({
        type: 'setState',
        payload: {
          invitedUserList: Users,
          loadingInvitedUserList: false,
          invitedUserListTotal: Total
        }
      });
    } catch (error) {
      handleError(error);
      yield put({
        type: 'setState',
        payload: {
          loadingInvitedUserList: false
        }
      });
    }
  },

  * getGroupTypes() {
    try {
      const data = yield call(api.getGroupTypes);
      yield put({
        type: 'setState',
        payload: {
          groupTypes: data,
        }
      })
    } catch (error) {
      handleError(error)
    }
  },

  * passVerification({ payload }) {
    const { userId } = payload;
    const userList = yield select(getUserList);
    yield put({
      type: 'setState',
      payload: {
        userList: userList.map(item => (item.p8Id === userId
          ? { ...item, Status: UserStatusEnums.Verified }
          : item))
      }
    });
  },

  * declineVerification({ payload }) {
    const { userId } = payload;
    const userList = yield select(getUserList);
    yield put({
      type: 'setState',
      payload: {
        userList: userList.map(item => (item.p8Id === userId
          ? { ...item, Status: UserStatusEnums.FailedVerify }
          : item))
      }
    });
  },

  * passServiceVerification({ payload }) {
    const { userId } = payload;
    const userList = yield select(getUserList);
    yield put({
      type: 'setState',
      payload: {
        userList: userList.map(item => (item.p8Id === userId
          ? { ...item, ServiceAuthStatus: UserStatusEnums.Verified }
          : item))
      }
    });
  },

  * declineServiceVerification({ payload }) {
    const { userId } = payload;
    const userList = yield select(getUserList);
    yield put({
      type: 'setState',
      payload: {
        userList: userList.map(item => (item.p8Id === userId
          ? { ...item, ServiceAuthStatus: UserStatusEnums.FailedVerify }
          : item))
      }
    });
  },

  * suspendUser({ payload }) {
    try {
      const { UserId } = payload;
      const msg = yield call(api.suspendUser, payload);
      message.success(msg);
      const userList = yield select(getUserList);
      yield put({
        type: 'setState',
        payload: {
          userList: userList.map(item => (item.p8Id === UserId ? { ...item, Suspended: true } : item))
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * unsuspendUser({ payload }) {
    try {
      const { UserId } = payload;
      const msg = yield call(api.unsuspendUser, payload);
      message.success(msg);
      const userList = yield select(getUserList);
      yield put({
        type: 'setState',
        payload: {
          userList: userList.map(item => (item.p8Id === UserId ? { ...item, Suspended: false } : item))
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * changeUserIdentities({ payload }) {
    const { UserId, Type } = payload
    const Identities = Type === KOL_TYPE.Default ? [] : [KOL_IDENTITIES.Expert]
    try {
      const IdentityTypes = [{
        Type,
        Level: KOL_IDENTITIES.Expert.Level
      }]
      yield call(api.setUserIdentities, {
        UserId,
        IdentityTypes
      })
      const userList = yield select(getUserList)
      yield put({
        type: 'setState',
        payload: {
          userList: userList.map(item => (item.p8Id === UserId ? { ...item, Identities } : item))
        }
      })
    } catch (error) {
      handleError(error)
    }
  },
  * getChannelInfo() {
    try {
      const data = yield call(api.getChannelInfo);
      const { Channels: channels } = data
      yield put({
        type: 'setState',
        payload: {
          channels,
        }
      })
    } catch (error) {
      handleError(error)
    }
  },
  * onChangePatientCare({ payload }) {
    try {
      const { UserIdOfInterest, PatientCareEnabled } = payload
      if (PatientCareEnabled) {
        yield call(api.disablePatientCare, { UserIdOfInterest })
      } else {
        yield call(api.enablePatientCare, { UserIdOfInterest })
      }
      const userList = yield select(getUserList)
      message.success(PatientCareEnabled ? '成功关闭患者服务' : '成功开通患者服务')
      yield put({
        type: 'setState',
        payload: {
          userList: userList.map(item => (item.p8Id === UserIdOfInterest ? { ...item, PatientCareEnabled: !PatientCareEnabled } : item))
        }
      })
    } catch (error) {
      handleError(error)
    }
  },
  * onChangePracticeInfo({ payload }) {
    try {
      const { UserIdOfInterest, PatientCareEnabled } = payload
      if (PatientCareEnabled) {
        yield call(api.disablePracticeInfo, { UserIdOfInterest })
      } else {
        yield call(api.enablePracticeInfo, { UserIdOfInterest })
      }
      const userList = yield select(getUserList)
      message.success(PatientCareEnabled ? '成功关闭执业信息' : '成功开通执业信息')
      yield put({
        type: 'setState',
        payload: {
          userList: userList.map(item => (item.p8Id === UserIdOfInterest ? { ...item, PatientCareEnabled: !PatientCareEnabled } : item))
        }
      })
    } catch (error) {
      handleError(error)
    }
  }
};
