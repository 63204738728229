import { message } from 'antd';
import { getSecurityErrorInfo, setSecurityErrorInfo } from '@UTILS/authority'

const handleError = err => message.error(err.message || err)

export const securityStrategyError = error => {
  if (error === '账号或密码错误') {
    let record = getSecurityErrorInfo()
    let errorCount = 1
    if (!record) {
      record = `1_${new Date().getTime()}`
    } else {
      errorCount = +record.split('_')[0] + 1
      record = `${errorCount}_${new Date().getTime()}`
    }
    setSecurityErrorInfo(record)
  }
}


export default handleError
