// export default {
//   namespace: 'common',
//   state: {
//     moduleName: '',
//     mode: '',
//     editData: {},
//   },

// };
// import { combineReducers } from 'redux';
import { Map, fromJS } from 'immutable';
import * as actions from '../actions';

export function common(
  state = { moduleName: '', mode: '', editData: {} },
  action
) {
  switch (action.type) {
    case actions.SMART_SET_MODULENAME:
      return Map(state)
        .set('moduleName', action.payload)
        .toJS();
    default:
      return state;
  }
}

export function data(state = {}, action) {
  switch (action.type) {
    case actions.SMART_SET_QUERYOBJ:
      return fromJS(state)
        .updateIn([action.moduleName, 'queryObj'], () => action.payload)
        .toJS();
    case actions.SMART_SELECT_SUCCESS:
      return fromJS(state)
        .updateIn([action.moduleName, 'moduleData'], () => action.payload)
        .toJS();

    default:
      return state;
  }
}

export default { common, data };
