export default {
  namespace: 'guide',
  state: {
    guideList: [],
    loadingGuideList: false,
    totalGuideList: 0,
    departmentList: [],

    //init
    initDepartIds: [],
    initSpecialtyIds: [],
    initIcdIds: [],
    openDrawerGuideId: ''
  }
};
