export default {
  namespace: 'user',
  state: {
    currentUser: {},
    userList: [],
    loadingUserList: true,
    userListTotal: 0,
    invitedUserList: [],
    loadingInvitedUserList: true,
    invitedUserListTotal: 0,
    kolConfirmLoading: false,
    groupTypes: {},
    channels: {}
  }
};
