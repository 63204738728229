import { message } from 'antd';
import { call, put, select, all } from 'redux-saga/effects';
import api from '@API';
import { LanguageEnum, ContentStatusEnums, LIST_DEFAULT_TAKE } from '@COMMON/constant';
import { FEED_STATUS, FEED_TYPE } from '@COMMON/feeds';
import { mapList } from '@UTILS/utils';
import handleError from '@UTILS/handleError'

const getCommentData = state => state.feed.commentList;
const getFeedDetail = state => state.feed.feedDetail;
const getContentList = state => state.feed.contentList;
const getArticleFeedList = state => state.feed.articleList;
const defaultTake = 20;

const getFeedOrArticleList = function* getFeedOrArticleList(type) {
  const contentList = yield select(getContentList);
  const articleList = yield select(getArticleFeedList);
  const storeData = type === FEED_TYPE.ARTICLE ? articleList : contentList
  const dataKey = type === FEED_TYPE.ARTICLE ? 'articleList' : 'contentList'
  return { dataKey, storeData }
}

export default {
  // * savePost({ payload }) {
  //   try {
  //     const data = yield call(api.savePost, payload)
  //     console.log(data)
  //     message.success('发布成功！')
  //     const postLoading = yield select(getPostLoading)
  //     yield put({
  //       type: 'setState',
  //       payload: {
  //         postLoading: false,
  //       }
  //     })
  //   } catch (error) {
  //     message.error(error.message)
  //   }
  // },

  * removeImage({ payload }) {
    try {
      yield call(api.removeImage, payload);
    } catch (error) {
      handleError(error);
    }
  },
  // 医院动态
  * getNewsFeedByGroupId({ payload }) {
    try {
      const hospitalFeedLists = yield call(api.getNewsFeedByGroupId, {
        take: LIST_DEFAULT_TAKE,
        ...payload,
      });
      const { FeedItems, Total } = hospitalFeedLists;
      yield put({
        type: 'setState',
        payload: {
          hospitalFeedLists: FeedItems,
          hospitalFeedTotal: Total
        }
      });
    } catch (error) {
      handleError(error);
    }
  },
  * getCustomerHospitals({ payload }) {
    const { skip, take = defaultTake } = payload;
    try {
      const { Groups } = yield call(api.getCustomerHospitals, payload);

      const mapHospitalLists = Groups.map(item => ({
        key: item.Id,
        value: item.Name[LanguageEnum.zh]
      }));
      yield put({
        type: 'setState',
        payload: {
          hospotalLists: mapHospitalLists,
          canLoadHospital: mapHospitalLists.length >= take,
          hospitalSkip: skip
        }
      });
    } catch (error) {
      handleError(error);
    }
  },
  // userFeed
  * getContentList({ payload }) {
    try {
      const { FeedItems, Total } = yield call(api.getContentList, {
        take: LIST_DEFAULT_TAKE,
        ...payload,
      });
      yield put({
        type: 'setState',
        payload: {
          contentList: FeedItems,
          contentListTotal: Total
        }
      });
    } catch (error) {
      handleError(error);
      yield put({
        type: 'setState',
        payload: {
          loadingContentList: false
        }
      });
    }
  },

  * blockFeedItem({ payload }) {
    try {
      yield call(api.blockFeedItem, payload)
      const contentList = yield select(state => state.feed.contentList);
      const { FeedItemId } = payload;
      yield put({
        type: 'setState',
        payload: {
          contentList: mapList(contentList, 'FeedItemId', FeedItemId, { Status: ContentStatusEnums.Blocked })
        }
      });
      message.success('屏蔽成功')
    } catch (error) {
      handleError(error)
    }
  },

  * unBlockFeedItem({ payload }) {
    try {
      yield call(api.unBlockFeedItem, payload)
      const contentList = yield select(state => state.feed.contentList);
      const { FeedItemId } = payload;
      yield put({
        type: 'setState',
        payload: {
          contentList: mapList(contentList, 'FeedItemId', FeedItemId, { Status: ContentStatusEnums.Active })
        }
      });
      message.success('取消屏蔽成功')
    } catch (error) {
      handleError(error)
    }
  },
  // ownFeed
  * getArticleList({ payload }) {
    try {
      yield put({ type: 'setState', payload: { loadingArticleFeed: true } })
      const { FeedItems, Total } = yield call(api.getArticleList, {
        take: LIST_DEFAULT_TAKE,
        ...payload,
      });
      yield put({
        type: 'setState',
        payload: {
          articleList: FeedItems,
          articleTotal: Total,
          loadingArticleFeed: false
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getOwnContentList({ payload }) {
    try {
      const { FeedItems, Total } = yield call(api.getOwnContentList, {
        take: LIST_DEFAULT_TAKE,
        ...payload,
      });
      yield put({
        type: 'setState',
        payload: {
          ownContentList: FeedItems,
          ownContentTotal: Total
        }
      });
    } catch (error) {
      handleError(error);
    }
  },
  * deleteOwnFeedItem({ payload }) {
    try {
      yield call(api.deleteOwnFeedItem, payload)
      const { FeedItemId } = payload;
      const preList = yield select(state => state.feed.ownContentList);
      const preTotal = yield select(state => state.feed.ownContentTotal);
      yield put({
        type: 'setState',
        payload: {
          ownContentList: preList.filter(item => item.FeedItemId !== FeedItemId),
          ownContentTotal: preTotal - 1
        }
      });
      message.success('删除成功');
    } catch (error) {
      handleError(error)
    }
  },

  * getItemDetail({ payload }) {
    try {
      yield put({
        type: 'setState',
        payload: {
          loadingFeedDetail: true
        }
      });
      const feedDetail = yield call(api.getContentDetail, payload);
      yield put({
        type: 'setState',
        payload: {
          feedDetail,
          loadingFeedDetail: false
        }
      });
    } catch (error) {
      handleError(error);
      yield put({
        type: 'setState',
        payload: {
          loadingFeedDetail: false
        }
      });
    }
  },

  * impersonateLike({ payload }) {
    try {
      const { FeedItemId } = payload;
      yield put({
        type: 'setState',
        payload: {
          confirmLikeLoading: true,
          closeLikeModal: false
        }
      });
      const { NewLikeCounts = 0 } = yield call(api.impersonateLike, payload);
      message.success(`点赞成功！新增${NewLikeCounts}人`);
      yield put({ type: 'getLikesGroup', payload: { fId: FeedItemId } });
      const feedDetail = yield select(getFeedDetail);
      yield put({
        type: 'setState',
        payload: {
          feedDetail: {
            ...feedDetail,
            LikeCount: feedDetail.LikeCount + NewLikeCounts
          }
        }
      });
      yield put({
        type: 'setState',
        payload: {
          confirmLikeLoading: false,
          closeLikeModal: true
        }
      });
    } catch (error) {
      handleError(error);
      yield put({
        type: 'setState',
        payload: {
          confirmLikeLoading: false
        }
      });
    }
  },

  * getLikesGroup({ payload }) {
    try {
      const likeList = yield call(api.getLikesGroup, payload);
      yield put({
        type: 'setState',
        payload: {
          likeList
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * getCommentList({ payload }) {
    try {
      const { skip = 0, take = defaultTake, fId } = payload;
      const { Total, Comments } = yield call(api.getComments, {
        fId,
        skip,
        take
      });
      const commentList = yield select(getCommentData);
      const nextList = skip === 0 ? Comments : [...commentList, ...Comments];
      yield put({
        type: 'setState',
        payload: {
          commentList: nextList,
          canLoadComment: nextList.length < Total,
          commentSkip: skip,
          commentTotal: Total
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * deleteComment({ payload }) {
    try {
      const { CommentId } = payload
      const commentList = yield select(getCommentData)
      const feedDetail = yield select(getFeedDetail)
      yield call(api.deleteComment, payload)
      yield put({
        type: 'setState',
        payload: {
          commentList: commentList.filter(item => item.p8Id !== CommentId),
          feedDetail: { ...feedDetail, CommentCount: feedDetail?.CommentCount - 1 }
        }
      })
    } catch (error) {
      handleError(error);
    }
  },

  * replyComment({ replyComment }) {
    try {
      const commentList = yield select(getCommentData)
      yield put({
        type: 'setState',
        payload: {
          commentList: [...commentList, replyComment]
        }
      })
    } catch (error) {
      handleError(error)
    }
  },

  * impersonateComment({ payload }) {
    try {
      yield put({
        type: 'setState',
        payload: {
          confirmCommentLoading: true,
          closeCommentModal: false
        }
      });
      const data = yield call(api.impersonateComment, payload);
      const commentList = yield select(getCommentData);
      const feedDetail = yield select(getFeedDetail);
      const commentTotal = feedDetail.CommentCount + 1;
      message.success('评论成功！');
      yield put({
        type: 'setState',
        payload: {
          confirmCommentLoading: false,
          closeCommentModal: true,
          commentList: [data, ...commentList],
          commentTotal,
          feedDetail: {
            ...feedDetail,
            CommentCount: commentTotal
          }
        }
      });
    } catch (error) {
      handleError(error);
      yield put({
        type: 'setState',
        payload: {
          confirmCommentLoading: false
        }
      });
    }
  },

  * unrecommendFeed({ payload }) {
    try {
      const { FeedItemId, type } = payload;
      const [, { dataKey, storeData }] = yield all([
        call(api.unrecommendFeed, payload),
        call(getFeedOrArticleList, type)
      ])
      message.success('取消推荐成功！');
      yield put({
        type: 'setState',
        payload: {
          [dataKey]: storeData.map(item => ({
            ...item,
            Recommended:
              FeedItemId === item.FeedItemId ? false : item.Recommended
          }))
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * recommendFeed({ payload }) {
    try {
      const { FeedItemId, type } = payload;
      const [, { dataKey, storeData }] = yield all([
        call(api.recommendFeed, payload),
        call(getFeedOrArticleList, type)
      ])
      message.success('推荐成功！');
      yield put({
        type: 'setState',
        payload: {
          [dataKey]: storeData.map(item => ({
            ...item,
            Recommended:
              FeedItemId === item.FeedItemId ? true : item.Recommended,
            RecommendDate:
              FeedItemId === item.FeedItemId ? Date.now() : item.RecommendDate
          }))
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * recycleArticle({ payload }) {
    try {
      const { FeedItemId } = payload
      yield call(api.recycleArticle, payload)
      message.success('文章已下架')
      const list = yield select(getArticleFeedList)
      yield put({
        type: 'setState',
        payload: {
          articleList: list.map(item => ({
            ...item,
            Status: item.FeedItemId === FeedItemId ? FEED_STATUS.DRAFT : item.Status,
          }))
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * saveArticle({ payload }) {
    try {
      const { FeedItemId, EntityId, Title, ...rest } = payload;
      const params = { PostId: EntityId, Title, ...rest };
      yield call(api.savePost, params);
      message.success('文章已上架！');
      const list = yield select(getArticleFeedList)
      yield put({
        type: 'setState',
        payload: {
          articleList: list.map(item => ({
            ...item,
            Status: item.FeedItemId === FeedItemId ? FEED_STATUS.ACTIVE : item.Status,
          }))
        }
      });
    } catch (error) {
      handleError(error);
    }
  },

  * publishArticle({ payload }) {
    try {
      const { FeedItemId } = payload
      yield call(api.publishArticle, payload)
      message.success('文章已上架！');
      const list = yield select(getArticleFeedList)
      yield put({
        type: 'setState',
        payload: {
          articleList: list.map(item => ({
            ...item,
            Status: item.FeedItemId === FeedItemId ? FEED_STATUS.ACTIVE : item.Status,
          }))
        }
      });
    } catch (error) {
      handleError(error)
    }
  },

  * archiveArticle({ payload }) {
    try {
      const { FeedItemId } = payload
      yield call(api.archiveArticle, payload)
      message.success('文章已删除')
      const list = yield select(getArticleFeedList)
      yield put({
        type: 'setState',
        payload: {
          articleList: list.map(item => ({
            ...item,
            Status: item.FeedItemId === FeedItemId ? FEED_STATUS.ARCHIVED : item.Status,
          }))
        }
      });
    } catch (error) {
      handleError(error);
    }
  }
};
