export default {
  namespace: 'commonData',
  state: {
    commonGroupList: [],
    calLoadMoreGroup: true,
    groupSkip: 0,
    topicList: [],
    canLoadMoreTopic: true,
    topicSkip: 0,
    specialtyList: [],
    canLoadMoreSpecialty: true,
    specialtySkip: 0,
    departMentList: [],
    canLoadMoreDepartMent: true,
    departMentSkip: 0,
    icdList: [],
    canLoadMoreIcd: true,
    icdSkip: 0,
    userGroupList: [],
    calLoadMoreUserGroup: true,
    userGroupSkip: 0,
    employeeSize: [],
    countries: [],
    canLoadCountry: false,
    ChinaProvinces: [],
    USStates: [],
    cities: [],
    businessTypeList: [],

    // commonSearchUser
    commonSearchUserLists: [],
    canLoadCommonUsers: true,
    commonUserSkip: 0,

    specialtiesList: [],
    commonOriginalList: [],
    canLoadOriginalList: true,
    commonOriginalListSkip: 0,

    ChinaDegree: [],
    USADegree: [],
    departmentList: [],
    positions: [],
  }
};
