export default {
  namespace: 'opportunity',
  state: {
    opportunityList: [],
    opportunityTotal: 0,
    opportunityLoading: false,

    ownOpportunityList: [],
    ownOpportunityTotal: 0,
    ownOpportunityLoading: false,

    applyOpportunityList: [],
    applyOpportunityTotal: 0,
    loadingApplyOpportunity: false
  }
};
